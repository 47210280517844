import React, {useRef} from 'react';

import {ArticlesSection} from 'src/components/homepage/ArticlesSection';
import {ClientsSection} from 'src/components/homepage/ClientsSection';
import {Hero} from 'src/components/homepage/Hero';
import {ImpactSection} from 'src/components/homepage/ImpactSection';
import {InvestSection} from 'src/components/homepage/InvestSection';
import {TestimonialsSection} from 'src/components/homepage/TestimonialsSection';
import {Box, SEOWithQuery} from 'src/components/shared';
import {seo} from 'src/utils/copy';

const IndexPage: React.FC = ({...props}) => {
  const containerMaxWidth = '80.1875rem';

  return (
    <Box backgroundColor="darkGreen">
      <Hero />
      <ClientsSection containerMaxWidth={containerMaxWidth} />
      <InvestSection containerMaxWidth={containerMaxWidth} />
      <TestimonialsSection containerMaxWidth={containerMaxWidth} />
      <ImpactSection containerMaxWidth={containerMaxWidth} />
      <ArticlesSection containerMaxWidth={containerMaxWidth} />

      <SEOWithQuery title={seo.homepageTitle} />
    </Box>
  );
};

export default IndexPage;
